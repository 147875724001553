<template>
  <page-layout :goback="true">
    <div id="submitOrder">
      <a-card class="address" title="选择收货地址">
        <a-input-search
          slot="extra"
          placeholder="搜索收货地址"
          enter-button="搜索"
          allowClear
          @search="loadAddressData"
        />
        <a-row class="chooise_address" :gutter="[16, 16]">
          <a-col
            :xxl="6"
            :xl="8"
            :md="12"
            :sm="12"
            v-for="item in addressList"
            :key="item.id"
          >
            <div
              class="address_box"
              :class="addressId == item.id ? 'active' : ''"
              @click="clickAdress(item.id)"
            >
              <div class="font14 flex">
                <span>{{ item.real_name }}</span>
                <a-icon
                  type="check-circle"
                  theme="filled"
                  class="green"
                  v-if="addressId == item.id"
                />
              </div>
              <div class="gray mart8">{{ item.phone }}</div>
              <div class="gray mart8">
                {{ item.province }} {{ item.city }} {{ item.district }}
                {{ item.detail }}
              </div>
              <div class="mart8">
                <span class="green cur" @click="$refs.editAddress.show(item)">
                  修改
                </span>
              </div>
            </div>
          </a-col>
          <a-col :xxl="6" :xl="8" :md="12" :sm="12">
            <div class="address_box add" @click="$refs.editAddress.show()">
              <div>
                <a-icon type="plus-circle" theme="filled" />
                <div class="add_address">添加收货地址</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="商品信息" v-if="orderInfo" style="margin-top: 12px">
        <a-table
          :columns="columns"
          :data-source="orderInfo.priceGroup.cartInfo || []"
          :pagination="false"
        >
          <div slot="goods" slot-scope="text, record" class="goods">
            <img :src="record.productInfo.image" alt="" class="img" />
            <div>
              <div class="describe">{{ record.productInfo.store_name }}</div>
              <div style="margin-top: 6px">
                已选:{{ record.productInfo.attrInfo.suk }}
                <span v-show="record.is_valid == 0" style="color: red">
                  该商品不支持配送
                </span>
              </div>
            </div>
          </div>
          <div slot="unitPrice" slot-scope="text, record">
            ￥{{ record.truePrice | price }}
          </div>
          <div slot="num" slot-scope="text, record">
            {{ record.cart_num }}
          </div>
          <div slot="free" slot-scope="text, record">
            ￥{{ record.storePostage }}
          </div>
          <div slot="total" slot-scope="text, record">
            ￥{{ record.totalMoney | price }}
          </div>
        </a-table>
      </a-card>
      <a-card title="定制商品" v-if="isCust" style="margin-top: 12px">
        <span slot="extra" style="color: red">
          * 定制商品无法进行退货,换货等售后操作
        </span>
        <a-form-model ref="custForm" :model="custData" layout="vertical">
          <a-form-model-item
            v-for="(item, index) in custData.custValue"
            :key="index"
            :label="item.name"
            :prop="'custValue.' + index + '.content'"
            :rules="[
              {
                required: item.required == 1,
                message: item.type == 1 ? '请输入内容' : '请上传图片',
                trigger: 'blur',
              },
            ]"
          >
            <a-input
              v-if="item.type == 1"
              v-model="item.content"
              placeholder="请输入"
              allowClear
            />
            <a-upload
              v-if="item.type == 2"
              list-type="picture-card"
              :file-list="item.fileList || []"
              :customRequest="
                (file) => {
                  customRequest(file, item);
                }
              "
              @change="
                (val) => {
                  uploadHandleChange(val, item);
                }
              "
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </a-card>
      <a-card title="订单备注" v-if="orderInfo" style="margin-top: 12px">
        <a-textarea
          v-model="mark"
          placeholder="请输入订单备注"
          allow-clear
          :rows="4"
          :maxlength="200"
        />
      </a-card>
      <a-affix :offset-bottom="0" v-if="orderInfo" style="margin-top: 12px">
        <a-card size="small">
          <div class="bottom-bar">
            <p class="gray font14" style="margin: 0">
              <span>订单金额：</span>
              <span class="origin mr26">
                ¥{{ orderInfo.priceGroup.totalPrice }}元
              </span>
            </p>
            <p class="gray font14" style="margin: 0">
              <span>运费合计：</span>
              <span class="origin mr26">
                ¥{{ orderInfo.priceGroup.storePostage }}元
              </span>
            </p>
            <p class="gray font14" style="margin: 0">
              <span>应付金额：</span>
              <span class="origin mr26">¥{{ orderInfo.payMoney }}元</span>
            </p>
            <a-button
              type="primary"
              :loading="payLoading"
              :disabled="isPayDisable"
              @click="pay"
            >
              去支付
            </a-button>
          </div>
        </a-card>
      </a-affix>
    </div>
    <edit-address ref="editAddress" @complete="loadAddressData" />
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import { orderDetail, confirmV2, createV2 } from "@/api/myOrder";
import { addressList } from "@/api/receivingAdress";
import { price } from "@/utils/filters";
import { uploadImg } from "@/api/myOrder";
import editAddress from "@/pages/bookBuy/receivingAdress/editAddress.vue";

export default {
  name: "submitOrder",
  components: { PageLayout, editAddress },
  filters: {
    price,
  },
  data() {
    return {
      originOrderId: "",
      cartId: [],
      new: "",
      addressList: [],
      addressId: "",
      info: {},
      orderInfo: undefined,
      columns,
      custData: {
        custValue: [],
      },
      mark: "",
      payLoading: false,
    };
  },
  computed: {
    isPayDisable() {
      return this.orderInfo.priceGroup.cartInfo.some((item) => {
        return item.is_valid == 0;
      });
    },
    isCust() {
      return (
        this.orderInfo &&
        this.orderInfo.priceGroup.cartInfo[0] &&
        this.orderInfo.priceGroup.cartInfo[0].productInfo.is_cust == 1
      );
    },
  },
  created() {
    this.new = this.$route.query.new;
    this.cartId = this.$route.query.cartId;
    this.originOrderId = this.$route.query.originOrderId;
    this.loadAddressData();
    this.getCartData(0);
    if (this.originOrderId) {
      this.loadOriginOrderData();
    }
  },
  methods: {
    loadAddressData(keyword) {
      addressList({
        keyword,
        page: 1,
        limit: 999,
      }).then((res) => {
        this.addressList = res.data.data.list;
        this.addressId =
          this.addressList.length > 0 ? this.addressList[0].id : "";
        this.getCartData(this.addressId);
      });
    },
    loadOriginOrderData() {
      orderDetail(this.originOrderId).then((res) => {
        try {
          this.custData.custValue = JSON.parse(res.data.data.custValue) || [];
        } catch (e) {
          this.custData.custValue = [];
        }
        this.custData.custValue.forEach((item, index) => {
          if (item.type == 2) {
            this.$set(
              this.custData.custValue[index],
              "fileList",
              item.content
                ? item.content.split(",").map((url, index2) => {
                    return {
                      url,
                      type: "image/jpeg",
                      status: "done",
                      uid: index2,
                      percent: 1,
                      name: index2 + ".jpg",
                    };
                  })
                : []
            );
          }
        });
      });
    },
    getCartData(addressId) {
      let cartId = "";
      if (Array.isArray(this.cartId)) {
        cartId = this.cartId.join(",");
      } else {
        cartId = this.cartId;
      }
      confirmV2({
        cartId: cartId,
        addressId: addressId,
        new: this.new,
      }).then((res) => {
        res.data.data.priceGroup.cartInfo.forEach((item) => {
          try {
            item.productInfo.cust_value =
              JSON.parse(item.productInfo.cust_value) || [];
          } catch (e) {
            item.productInfo.cust_value = [];
          }
        });
        if (!this.custData.custValue.length) {
          this.custData.custValue =
            res.data.data.priceGroup.cartInfo[0].productInfo.cust_value;
        }
        this.orderInfo = res.data.data;
      });
    },
    clickAdress(id) {
      this.addressId = id;
      this.getCartData(id);
    },
    customRequest({ file }, item) {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.$message.error("请上传JPG或PNG格式文件");
        return false;
      }
      new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          this.upLoadImgs(item, file, fileReader.result);
        };
      });
    },
    upLoadImgs(item, file, data) {
      uploadImg({ file: data })
        .then((res) => {
          let val = item.fileList.find((i) => i.uid == file.uid);
          if (res.data.status == 200) {
            val.status = "done";
            val.url = res.data.data.url;
            this.$set(
              item,
              "content",
              item.fileList.map((i) => i.url).join(",")
            );
          } else {
            val.status = "error";
            this.$Message.error(res.data.msg);
          }
        })
        .catch(() => {
          item.fileList.find((i) => i.uid == file.uid).status = "error";
        });
    },
    uploadHandleChange({ fileList }, item) {
      this.$set(item, "fileList", fileList);
    },
    async pay() {
      if (this.addressId == "") {
        this.$message.warning("请选择地址");
        return;
      }
      if (this.orderInfo.valid_count == 0) {
        this.$message.warning("该地区不支持配送");
        return;
      }
      try {
        if (this.isCust) {
          await this.$refs.custForm.validate();
        }
        this.payLoading = true;
        let dict = {
          mark: this.mark,
          addressId: this.addressId,
        };
        if (this.isCust) {
          var array = [...this.custData.custValue];
          array.forEach((item) => {
            delete item.fileList;
          });
          dict.cust_value = JSON.stringify(array);
        }
        createV2(this.orderInfo.orderKey, dict)
          .then((res) => {
            if (res.data.status == 200) {
              this.$message.success(res.data.msg);
              this.$router.push({
                path: "orderPay",
                query: {
                  orderNum: res.data.data.result.orderId,
                },
              });
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .finally(() => {
            this.payLoading = false;
          });
      } catch (error) {
        return;
      }
    },
  },
};

const columns = [
  {
    title: "商品名称",
    dataIndex: "goods",
    key: "goods",
    width: 362,
    scopedSlots: { customRender: "goods" },
    align: "center",
  },
  {
    title: "单价",
    dataIndex: "unitPrice",
    key: "unitPrice",
    scopedSlots: { customRender: "unitPrice" },
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "num",
    key: "num",
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  // {
  //   title: '运费',
  //   dataIndex: 'free',
  //   key: 'free',
  //   scopedSlots: { customRender: 'free' },
  //   align: 'center'
  // },
  {
    title: "共计",
    dataIndex: "total",
    key: "total",
    scopedSlots: { customRender: "total" },
    align: "center",
  },
];
</script>

<style>
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn
  .ant-input {
  padding-top: 10px;
  font-size: 12px;
  resize: none;
}
.mr26 {
  margin-right: 26px;
}
.bottom-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
<style lang="less" scoped>
@import "submitOrder";
</style>
